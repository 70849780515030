import { Show, batch } from "solid-js";
import { useCapencyContext } from "~/contexts/CapencyContext";

type Props = {
  isLoading: boolean;
  showFailedPanel: boolean;
  email?: string;
  phone?: string;
  emailMessage?: string;
  phoneMessage?: string;
};

export default function CapencyReporter(props: Props) {
  const [, { setBypassingCapency, setCapencyShowFailedPanel }] =
    useCapencyContext();

  return (
    <>
      <Show when={props.isLoading}>
        <div class="spinner">
          <i aria-hidden="true" class="loading-spinner" />
        </div>
      </Show>

      <Show when={props.showFailedPanel}>
        <div class="cap-form-validation">
          <h3>Certaines informations semblent erronées</h3>
          <p class="intro">
            Ces informations nous permettent de valider votre demande, d’en
            suivre l’évolution puis de vous transmettre les éléments
            nécessaires.
          </p>

          <Show when={props.emailMessage}>
            <div class="error-part">
              <p>{props.email}</p>
              <p class="error">{props.emailMessage}.</p>
            </div>
          </Show>

          <Show when={props.phoneMessage}>
            <div class="error-part">
              <p>{props.phone}</p>
              <Show
                when={props.phoneMessage === "Rejetée"}
                fallback={<p class="error">{props.phoneMessage}.</p>}
              >
                <p class="error">Votre numéro de téléphone n'est pas valide.</p>
              </Show>
            </div>
          </Show>

          <button
            type="button"
            class="btn btn-flat"
            onClick={() => {
              setCapencyShowFailedPanel!(false);
            }}
          >
            Modifier
          </button>
          <button
            type="button"
            class="btn btn-outline"
            onClick={() => {
              batch(() => {
                setBypassingCapency!(true);
                setCapencyShowFailedPanel!(false);
              });
            }}
          >
            Envoyer sans modifier
          </button>
        </div>
      </Show>
    </>
  );
}
