import { createSignal, splitProps } from "solid-js";
import { Select } from "~/components/Forms/Fields/Base/Select";

import type {
  InputOption,
  SchemaOptions,
  SelectPropsWithOptionalOptions,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { picklist, safeParse } from "valibot";
import ValidationErrors from "./Base/ValidationErrors";

const LABEL = "Civilité";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = true;

export const availableOptions: InputOption[] = [
  {
    label: "M.",
    value: "02",
    name: "mr",
  },
  {
    label: "Mme",
    value: "03",
    name: "miss",
  },
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function CivilitySchema(options?: SchemaOptions) {
  return {
    [options?.name ?? "civility"]: picklist(
      availableOptions.map((o) => o.value),
      "Veuillez sélectionner une civilité",
    ),
  };
}

export function Civility(props: SelectPropsWithOptionalOptions) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const ret = safeParse(
      CivilitySchema({ name: props.name ?? "civility" })[
        props.name ?? "civility"
      ],
      input,
    );
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <Select
          label={local.label || LABEL}
          help={local.help || HELP}
          validateFn={validate}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          options={availableOptions}
          {...others}
        />
        <ValidationErrors errors={validationErrors()} field={props.name} />
      </div>
    </>
  );
}
